import React from 'react';

import {
  Box,
  Divider,
  Heading,
  Link,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import {
  IOfferingBaseScopeEnum,
  IOfferingScopeEnum,
} from '@r-client/data/graphql-types';
import { useAppConfig } from '@r-client/shared/util/core';

import { useTickerInvestmentsQuery } from '../../graphql/ticker-investments-query';
import { CardButton } from '../card-button/card-button';
import { EuOfferings } from '../eu-offerings/eu-offerings';
import { IconNotice } from '../icon-notice/icon-notice';
import { LastInvestments } from '../last-investments/last-investments';
import { UsOfferings } from '../us-offerings/us-offerings';

export interface IGlobalForInvestorsProps {
  headingRef: React.RefObject<HTMLHeadingElement>;
}

export const GlobalForInvestors = observer(function GlobalForInvestors({
  headingRef,
}: IGlobalForInvestorsProps) {
  const { data: lastInvestmentsData, isLoading: isLoadingLastInvestments } =
    useTickerInvestmentsQuery({
      variables: {
        first: 16,
      },
    });

  const { republicEuropeBaseUrl } = useAppConfig();
  return (
    <Box bg="grey.50" py="20" px="4">
      <Box maxWidth="1152px" mx="auto">
        <Text textStyle="sm" color="grey.500" ref={headingRef}>
          For investors
        </Text>
        <Heading size="republic-h4" my="1">
          Build a portfolio of the world’s most ambitious ventures
        </Heading>
        <Text textStyle="md" color="grey.500" maxW="724px" mb="8">
          Republic is a leading global investment platform for community
          finance, empowering everyone to build a portfolio of meaningful
          investments.
        </Text>

        {isLoadingLastInvestments || !lastInvestmentsData ? (
          false
        ) : (
          <Box mt="8" mb="14">
            <LastInvestments
              tickerInvestments={lastInvestmentsData.tickerInvestments}
              noBorder
              noMargin
            />
          </Box>
        )}

        <Divider color="grey.100" />

        <Box my="6">
          <Heading size="republic-h4" mb="1">
            US deals
          </Heading>
          <Text textStyle="sm" color="grey.500">
            Only public open deals are shown.{' '}
            <Link
              variant="republic-underlined"
              href="/login"
              size="republic-default"
            >
              Log in
            </Link>{' '}
            to see all deals you’re eligible to invest in
            <IconNotice text="Regulation CF deals are open to all investors. Some types of investors (accredited or non-U.S.) may access additional deals under other regulations." />
          </Text>
        </Box>

        <UsOfferings
          first={3}
          scopeFilter={{
            scopeName: IOfferingScopeEnum.MostTraction,
            mostTractionPeriodDays: 3,
          }}
          scopeBaseFilter={[
            IOfferingBaseScopeEnum.Published,
            IOfferingBaseScopeEnum.ShowOnHomepage,
          ]}
          viewAllButtonHref="/companies?sorting=most-traction-last-week"
          viewAllButtonText="View all US deals"
        />

        <Divider color="grey.100" mt="20" mb="6" />

        <Box my="6">
          <Heading size="republic-h4" mb="1">
            European deals
          </Heading>
          <Text textStyle="sm" color="grey.500">
            Only EU and UK residents are currently allowed to invest via
            Republic Europe
            <IconNotice text="The campaigns listed are examples of Republic Europe campaigns, and are not offers in the US or to US persons." />
          </Text>
        </Box>

        <EuOfferings
          first={3}
          sort="most_recent_desc"
          viewAllButtonHref={`${republicEuropeBaseUrl}/invest/raising-now?sort=trending_desc&sort_collection=campaigns`}
          viewAllButtonText="View all European deals"
        />

        <Heading size="republic-h4" mt="14" mb="6">
          More ways to invest
        </Heading>
        <SimpleGrid columns={[1, 4]} gap={{ base: 4, md: 8 }} mb="14">
          <CardButton
            title="Accredited deals"
            description="View investment opportunities for US accredited investors"
            href="/dealroom"
          />
          <CardButton
            title="Digital hedge fund"
            description="Liquid digital asset investments led by an experienced, active trading team"
            href="/digital"
          />
          <CardButton
            title="Institutional capital"
            description="Venture arm focused on investing in frontier technologies"
            href="/capital"
            isExternal={true}
          />
          <CardButton
            title="Investor network"
            description="Become a member to save on fees and get the best of Republic"
            href="/innovation"
          />
        </SimpleGrid>
      </Box>
    </Box>
  );
});
